import React, {Component} from 'react';
import * as http from 'axios';
const mapStyles = {
    width: '100%',
    height: '100%',
    position: 'absolute'
};
//
// let markers = [];
class GoogleMap extends Component {
    state = {
        tractors: [],
        tractors_endpoint: 'https://baas.kinvey.com/rpc/kid_SygM_Zn1Q/custom/fetchChildAccountTractors',
        user: JSON.parse(localStorage.getItem('auth_user')),
        markers: []
    };

    render() {
        return (
            <div id="map" style={mapStyles}></div>
        )
    }

    componentDidMount() {
        var map;
        var google = window.google;
        var infoWindow = function renderInfoWindow(tractor)
        {
            return new google.maps.InfoWindow({
                content: `
                    <ul>
                        <li>Tractor Name: ${tractor.TractorName} </li>
                        <li>Tractor ID: ${tractor.TractorID} </li>
                        <li>Latitude: ${tractor.PositionLatitude ? tractor.PositionLatitude : 'NA' } </li>
                        <li>Longitude: ${tractor.PositionLongitude ? tractor.PositionLongitude : 'NA'} </li>
                        <li>Address: ${tractor.Street ? tractor.Street: ''} , ${tractor.Town ? tractor.Town:''}, ${tractor.Country?tractor.Country:''}</li>
                        <li>Status: ${tractor.Status === 0 ? 'OFFLINE' : 'ONLINE'} </li>
                    </ul>
                    `
            });
        }

        map = new google.maps.Map(document.getElementById('map'), {
            zoom: 5,
            center: {lat: 9.081999, lng: 8.675277 }
        });

        // Create an array of alphabetical characters used to label the markers.
        var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        // let url = "https://ec2-18-237-108-176.us-west-2.compute.amazonaws.com/api/tractorsLocation/100051";
        var user_id = JSON.parse(localStorage.getItem('auth_user'))._id;

        let url = "https://baas.kinvey.com/rpc/kid_SygM_Zn1Q/custom/fetchChildAccountTractors";
        fetch(url, {
            headers:{
                "Accept": "application/json",
                "Content-Type":"application/json",
                "Authorization": "Basic a2lkX1N5Z01fWm4xUToxYjA0MzM1Y2E5OGQ0YTA5YTA2ODc1N2VhNDRhOTU3Yg=="
            },
            method: "POST",
            body: JSON.stringify({
                user_id: user_id
            })
        }).then( resp => resp.json() )
            .then( data =>  {
                var tractors = data.tractors;
                console.log("we got tractors", tractors);
                var locationsArr = [];
                if( tractors && tractors.length ) {
                    tractors.forEach( tractor => {
                        locationsArr.push (
                            { 'lat': Number(tractor.PositionLatitude)  , 'lng': Number(tractor.PositionLongitude)  }
                        )
                    });
                }


                var markers = locationsArr.map(function(location, i) {
                    var marker = new google.maps.Marker({
                        position: location,
                        //label: labels[i % labels.length],
                        icon: 'https://www.hellotractor.com/wp-content/uploads/2019/04/ic_cluster_item_marker-3.png',
                        title: 'Tractor Detials=',
                        animation: google.maps.Animation.DROP
                    });

                    marker.addListener('click', function(e){
                        infoWindow(tractors[i]).open(map, marker);

                    });

                    return marker;
                });

                var clusterStyles = [
                    {
                        textColor: '#F04A25',
                        url: 'https://www.hellotractor.com/wp-content/uploads/2019/04/ic_cluster_item_marker-2.png',
                        height:95,
                        width: 65
                    },
                ];

                var mcOptions = {
                    styles: clusterStyles,
                };

                //Add a marker clusterer to manage the markers.
                var markerCluster = new window.MarkerClusterer(map, markers,mcOptions);
            });

    }

}

export default GoogleMap;

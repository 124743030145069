import React, { Component } from 'react';
import * as swal from 'sweetalert';
import { NavLink } from "react-router-dom";
const navBgStyle = {
    color : '#fff'
};

const navLinkStyles = {
    ...navBgStyle,
    border: '1px solid #fff',
    marginLeft: '10px',
    borderRadius: '5px'
}
class AppDashboardHeader extends Component
{
    constructor(){
        super();
        this.state = {
            user:JSON.parse(localStorage.getItem('auth_user'))
    }

    }
    render() {
        return (
            <div>
            <nav className="navbar navbar-expand-sm bg-success justify-content-center" style={navBgStyle}>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link">Hi {this.state.user.first_name}, welcome to your dashboard.</a>
                    </li>
                    <li className="nav-item" >
                        <NavLink className="nav-link" style={navLinkStyles} activeClassName="active" to="/tracking">Live Tracking</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" activeClassName="active" style={navLinkStyles} to="/tractors">Tractors</NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link" activeClassName="active" style={navLinkStyles} onClick={this.logUserOut} to="/logout">Log Out</NavLink>
                    </li>
                </ul>
            </nav>
            </div>
        )
    }

 logUserOut = e => {
        localStorage.removeItem('auth_user');
        swal({
            title: 'Logging Out Successful',
            text: 'You have been logged out successfully, have a great time',
            icon:'success',
            button: 'OK'
        }).then( () => {
            window.location.href = '/'
        })
    }
}


export default AppDashboardHeader;

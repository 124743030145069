import React, { Component } from 'react';
import AuthenticationProvider from '../providers/AuthenticationProvider';
import * as swal from 'sweetalert';

const login_text_style = {
    opacity:0.5
};

const logo_style = {
    marginBottom: '15%'
}
const heading_style = {
    color: '#218838'
};

class AppLogin extends Component {
    constructor(){
        super();
        this.state = {
            login_text: 'Log In',
            errors: [],
            user: {
                username:'',
                password: ''
            },
            loginBgStyles: {
                backgroundImage: 'url(../images/dashboard-image.jpg)',
                backgroundSize : '100% 100%',
                backgroundPosition: 'center',
                width: '100%',
                height: '800px',
                'overflow-y': 'hidden'
            }

        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-7" >
                    <div style={this.state.loginBgStyles}></div>
                </div>


                <div className="col-md-5 login_form">
                    <center><img src="images/name-logo.png" width={204} height={140} style={logo_style} /></center>

                    <form className="" onSubmit={this.authenticateUser}>
                        <h3 style={heading_style}> Dashboard Login </h3>
                        <p style={login_text_style}> Provide your email address and password to access your dashboard</p>
                        <ul>
                            { this.state.errors.map( error=> <li> {error} </li>) }
                        </ul>


                        <div className="form-group">
                            <label htmlFor="email">
                                Email Address
                            </label>
                            <input type="text" id="email" name="email" value={this.state.user.username} onChange={this.updateUserEmail}  autoComplete="off" className="form-control"/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="pwd">
                               Password
                            </label>
                            <input id="pwd" type="password" name="password" value={this.state.user.password} onChange={this.updateUserPassword} autoComplete="off" className="form-control"/>
                        </div>

                        <div className="form-group">
                            <button className="btn btn-success btn-active form-control"> {this.state.login_text} </button>
                        </div>

                    </form>
                </div>
            </div>
        );
    }

    authenticateUser = e => {
        e.preventDefault();
        const validationPassed = this.validateRequest();

        if( !validationPassed ){
            //Display an alert of errors
            swal({
                title: 'There were error(s)',
                text: 'Please provide your username and password',
                icon: 'error',
                button: 'Ok'
            });

            return false;
        }

        // Proceed to authenticate the user
        const authProvider = new AuthenticationProvider();
        this.setState({login_text: 'authenticating...'});

        authProvider.auth(this.state.user).then( authResponse => {
            if( authResponse.status === 200 ){
                // Persist auth state to localStorage
                localStorage.setItem('auth_user', JSON.stringify(authResponse.data));
                swal({
                    text: 'You have logged in successfully, have a great time',
                    icon: 'success',
                    button: 'Proceed',
                    title: 'Authentication Successful'

                }).then( () => {
                    this.props.history.push('/dashboard')
                })
            }

        }).catch( err => {
            swal({
                text: 'Whoops, you provided wrong credentials',
                icon: 'error',
                button: 'Try Again',
                title: 'Authentication Error'
            })

            console.log ( new Error(err).message );
            this.setState({login_text: 'Login'});
        });

    };

    updateUserEmail = e => {
        const user = {...this.state.user};
        user.username = e.target.value;
        this.setState({user})
    };

    updateUserPassword = e => {
        const user = {...this.state.user};
        user.password = e.target.value;
        this.setState({user})
    };

    validateRequest = () => {

        this.setState({errors: []});

        if( ! this.state.user.username ){
            this.state.errors.push('Please provide your email address')
        }
        if( ! this.state.user.password ){
            this.state.errors.push('Please provide your password')
        }

        if(this.state.errors.length){
            return false;
        }
        return true;
    }

    componentDidMount() {
        const auth_user = localStorage.getItem('auth_user');
        if( auth_user ){
            this.props.history.push('/dashboard')
        }
    }
}

export default AppLogin;

import React from 'react';
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import ReactDOM from 'react-dom';
import { createHashHistory } from "history";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Local imports
import AppDashboard from './components/AppDashboard';
import AppLogin from './components/AppLogin';
import TractorList from './components/TractorsList';

const hist = createHashHistory();

const routing = (
    <Router history={hist}>
        <div>
        <Route exact path="/" component={AppLogin} />
        <Route path="/dashboard" component={AppDashboard} />
        <Route path="/tracking" component={AppDashboard} />
        <Route path="/tractors" component={TractorList} />

        </div>
    </Router>
);
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {Component} from "react";
import GoogleMap from '../components/GoogleMap';
import AppDashboardHeader from '../components/AppDashboardHeader';
class AppDashboard extends Component {
    constructor(){
        super();
    }

    render() {
        return (
            <div>
            <AppDashboardHeader/>
            <GoogleMap/>
            </div>
        )
    }
}

export default AppDashboard;

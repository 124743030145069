import React, { Component } from 'react';
import * as http from 'axios';
import Loader from 'react-loader-spinner';
import AppDashboardHeader from '../components/AppDashboardHeader';
const tableHeadingStyles = {
    color: '#148a3e'
}
class TractorsList extends Component {

    constructor(){
        super();
        this.state = {
            tractors: [],
            tractors_endpoint: 'https://cloud.hellotractor.com/api/v1/tractors/100051',
            loaderStyles: {
                position: 'fixed',
                top: '0px',
                left: '50%',
                bottom: '0px',
                right: '0px',
                display: 'flex',
                alignItems: 'center',
                overflow: 'auto'
            }
        }
    }
    render() {
        return (
            <div>
                <AppDashboardHeader/>
            <table className="table table-hover">
                <thead style={tableHeadingStyles}>
                    <tr>
                        <th> S/N </th>
                        <th> TractorID </th>
                        <th> Tractor Name </th>
                        <th> Tractor Location </th>
                        <th> Latitude </th>
                        <th> Longitude </th>
                        <th> Last Active Date </th>
                        <th> Status </th></tr>
                </thead>
                <tbody>
                { this.state.tractors.length === 0 ? <div style={this.state.loaderStyles}>
                        <Loader type="Oval" color="#148a3e" height={80} width={80} />
                </div> : "" }
                { this.state.tractors.map( (tractor, index) =>
                    <tr key={index}>
                        <td>{ index+=1}</td>
                        <td> {tractor.tracker_id} </td>
                        <td> {tractor.name} </td>
                        <td> {tractor.address} </td>
                        <td> {tractor.latitude} </td>
                        <td> {tractor.longitude} </td>
                        <td> {tractor.last_reported_time} </td>
                        <td> {tractor.status === 0 ? 'OFFLINE' : 'ONLINE'}</td>
                    </tr>
                )}
                </tbody>

            </table>
            </div>
        )
    }

    componentDidMount() {

        let user = JSON.parse(localStorage.getItem('auth_user'));
        if( user ) {
            http.get(this.state.tractors_endpoint, {headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/js'
                }}).then( response => {
                    this.setState({tractors: response.data});
            }).catch( err => {
                console.log( new Error(err).message);
            })

            return false;
        }

        window.location.href = '/'

    }
}

export default TractorsList;

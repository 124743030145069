import React, { Component } from 'react';
import AppLogin from './components/AppLogin';
import './App.css';

class App extends Component {
  render() {
    return (
       <AppLogin />
    );
  }
}

export default App;

import * as http from 'axios';

class AuthenticationProvider {
    constructor(){
        this._auth_endpoint = 'https://baas.kinvey.com/user/kid_SygM_Zn1Q/login';
        this._headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':'Basic a2lkX1N5Z01fWm4xUToxYjA0MzM1Y2E5OGQ0YTA5YTA2ODc1N2VhNDRhOTU3Yg=='
        }
    }
    auth(data){
        return http.post(this._auth_endpoint, data, {headers: this._headers} );
    }
}

export default AuthenticationProvider;
